import axios from 'axios'
import axiosInstance from "../services/AxiosInstance"

const endpoints = {
    loginEndpoint: `${process.env.REACT_APP_API_URL}/public/login`,
    resetMailEndpoint: `${process.env.REACT_APP_API_URL}/public/resetMail`,
    updateUserEndpoint: `${process.env.REACT_APP_API_URL}/private/users/updateUser`
}

function login (...params) {
        return axios.post(endpoints.loginEndpoint, ...params)
}

function resetMail (params) {
        return axios.post(endpoints.resetMailEndpoint, {email: params})
}

function updateUser (params) {
        return axiosInstance.post(`${endpoints.updateUserEndpoint}/${params.user_id}`, params.userChanges) //recives an object with the user id and another with the keys being the column names and the values the value to change to
}

const authService = {
    login,
    resetMail,
    updateUser
}

export default authService