import axiosInstance from "../services/AxiosInstance"

export const USERS_URL = `${process.env.REACT_APP_API_URL}/private/users`

// CREATE =>  POST: add a new user to the server
export function getUserByIdReport(id) {
    return axiosInstance.post(`${USERS_URL}/report`,  { id });
}

// READ
export function getAllUsers() {
    return axiosInstance.get(`${USERS_URL}/getAll`)
}

// READ
export function getAllAddress(id) {
    return axiosInstance.post(`${USERS_URL}/getAllAddress`, {user_id: id}); //just userId
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
    return axiosInstance.post(`${USERS_URL}/find`, { queryParams })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsersAdmin(queryParams) {
    return axiosInstance.get(`${USERS_URL}/getAll`, { queryParams })
}

export function unblockUser(id) {
    return axiosInstance.get(`${USERS_URL}/unblock/${id}`);
}

export function createAddress(queryParams) {
    return axiosInstance.post(`${USERS_URL}/address/create`, queryParams)
}

// example object for createAddress
// {
//     city: "city",
//     colony: "colony",
//     num_ext: "1",
//     num_int: "1",
//     postal_code: "00000",
//     state: "state",
//     street: "street",
//     user_id: 26
// }

const usersService = {
    getUserByIdReport,
    getAllUsers,
    getAllAddress,
    findUsers,
    findUsersAdmin,
    unblockUser,
    createAddress
}

export default usersService