import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import productsCrud from './productsCrud'

export const fetchAllProducts = createAsyncThunk(
    'products/fecthAll',
    async () => {
        const response = await productsCrud.getAllProducts()
        return response
    }
)

export const findProducts = createAsyncThunk(
    'products/findProducts',
    async (params) => {
        const response = await productsCrud.findProducts(params)
        return response
    }
)

export const findProducstAsAdmin = createAsyncThunk(
    'products/findProducstAsAdmin',
    async (params) => {
        const response = await productsCrud.findProductsAdmin(params)
        return response
    }
)

const initialState = {
    adminFecth: false,
    areProducts: false,
    areProductsComplete: false,
    products: [],
    productsComplete: [],
    productsTotal: null,
    error: null
}

const productsSlice = createSlice({
    name: 'products',
    initialState,
    extraReducers: {
        [fetchAllProducts.fulfilled]: (state, action) => {
            state.adminFecth = false
            state.areProductsComplete = true
            state.productsComplete = action.payload.data.products
            state.productsTotal = action.payload.data.products.length
            state.error = null
        },
        [fetchAllProducts.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [findProducts.fulfilled]: (state, action) => {
            state.adminFecth = false
            state.areProducts = true
            state.products = action.payload.data.products
            state.productsTotal = action.payload.data.products.length
            state.error = null
        },
        [findProducts.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [findProducstAsAdmin.fulfilled]: (state, action) => {
            state.adminFecth = true
            state.areProducts = true
            state.products = action.payload.data.products
            state.productsTotal = action.payload.data.products.length
            state.error = null
        },
        [findProducstAsAdmin.rejected]: (state, action) => {
            state.error = action.error.message
        }
    }
})

export const { } = productsSlice.actions

export default productsSlice.reducer
