import axios from "axios"
import axiosInstance from "../services/AxiosInstance"

export const ORDERS_URL = `${process.env.REACT_APP_API_URL}/private/orders`

export function createOrder(order) {
    if (order.edit) {
        return axiosInstance.post(ORDERS_URL + '/updateC',  order)
    } else {
        return axiosInstance.post(ORDERS_URL + '/create',  order)
    }
}

export function getAllOrders(saved = false) {
    return axiosInstance.get(`${ORDERS_URL}/${saved ? 'getAllSaved' : 'getAll' }`)
}

export function getAllFiles() {
    return axiosInstance.get(`${ORDERS_URL}/getFiles`)
}

export function getOrderById(orderId) {
    return axiosInstance.get(`${ORDERS_URL}/${orderId}`) // recives order id
}

export function findOrders(userId) {
    return axiosInstance.post(`${ORDERS_URL}/getAllByUser`,  {user_id: userId}) // recives an interger for the userId corresponding to user id
}

export function findOrdersByUser(data) {
    console.log(data)
    return axiosInstance.post(`${ORDERS_URL}/${data.saved ? 'getAllByUserSaved ' : 'getAllByUser'}`, {user_id: data.userId}) // recives an interger for the userId corresponding to user id
}

const ordersService = {
    createOrder,
    getAllOrders,
    getAllFiles,
    getOrderById,
    findOrders,
    findOrdersByUser
}

export default ordersService


// order for createOrder example

// const order = {
//     order:{
//       initialDate: '2022-09-14',
//       address_id: 39,
//       client_id: null,
//       user_id: 23,
//       comment: 'comment',
//       total: 650,
//       invoice: 0,
//       payment_method:1,
//       status: 1,
//       saveOrder: 1
//     },
//     products: [
//       {
//           product_id: 165,
//           name: "Bio Detergente Ropa blanca",
//           sku: "Kit",
//           brand: "Hotch",
//           imageUrl: "",
//           model: "1L",
//           external_price: 650,
//           stock: 10000,
//           hide: 0,
//           createdAt: "2020-07-14",
//           updatedAt: "2022-06-14",
//           price: "850",
//           quantity: 1
//       }
//     ],
//     shipment:{
//       type:1,
//       date:'2022-09-14',
//       phone:5566778899,
//       name:'someName'
//     },
//     edit: false
//   }
