import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import usersCrud from './usersCrud'

export const fecthUserReport = createAsyncThunk(
    'users/fecthUserReport',
    async params => {
        const response = await usersCrud.getUserByIdReport(params)
        const team = response.data.teamData
        const teamMapForListComponent = team.map(teamMember => {
            return {
              user_name: `${teamMember.user.name} ${teamMember.user.last_name_1} ${teamMember.user.last_name_2}`,
              Addres_city: `${teamMember.user.Address.city}`,
              Address_colony: `${teamMember.user.Address.colony}`,
              email: `${teamMember.user.email}`,
              createdAt: `${teamMember.user.createdAt}`
            }
          })
        response.data = {...response.data, teamMapForListComponent}
        return response
    }
)

export const fetchAllUsers = createAsyncThunk(
    'users/fecthAll',
    async () => {
        const response = await usersCrud.getAllUsers()
        return response
    }
)

export const fetchAddress = createAsyncThunk(
    'users/fetchAddress',
    async params => {
        const response = await usersCrud.getAllAddress(params)
        return response
    }
)

export const findUsersAsAdmin = createAsyncThunk(
    'users/findAsAdmin',
    async (params) => {
        const response = await usersCrud.findUsersAdmin(params)
        return response
    }
)

export const unblockUser = createAsyncThunk(
    'users/unblock',
    async params => {
        const response = await usersCrud.unblockUser(params)
        return response
    }
)

export const createAddress = createAsyncThunk(
    'users/createAddress',
    async (params) => {
            const objectForTestingValues = {...params}
            delete objectForTestingValues.num_int
            const isParamEmpty = Object.values(objectForTestingValues).includes('')
            if (!isParamEmpty) {
                const response = await usersCrud.createAddress(params)
                return response
            } else {
                return rejected()
            }
    }
)

const initialState = {
    users: [],
    auxUsers: null,
    areUsers: false,
    userReport: null,
    addresses: {},
    error: null,
    updatedAddressMessage: ''//,
    // isLoading: false
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearUpdateAddress(state) {
            state.updatedAddressMessage = ''
        }
    },
    extraReducers: {
        [fecthUserReport.fulfilled]: (state, action) => {
            state.userReport = action.payload.data
            state.error = null
        },
        [fecthUserReport.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchAllUsers.fulfilled]: (state, action) => {
            state.areUsers = true
            state.users = action.payload.data.users
            state.auxUsers = action.payload.data.auxUsers
            state.error = null
        },
        [fetchAllUsers.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchAddress.fulfilled]: (state, action) => {
            state.addresses = action.payload.data
            state.error = null
        },
        [fetchAddress.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [findUsersAsAdmin.fulfilled]: (state, action) => {
            state.areUsers = true
            state.users = action.payload.data.users
            state.auxUsers = action.payload.data.auxUsers
            state.error = null
        },
        [findUsersAsAdmin.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [unblockUser.fulfilled]: (state, action) => {
            console.log(action.payload) // then map userUpdated userSlice
            state.error = null
        },
        [unblockUser.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [createAddress.fulfilled]: (state, action) => {
            state.updatedAddressMessage = 'Se ha agregado una dirección'
            // state.addresses.address = [...state.addresses.address, action.payload.data]
            // state.isLoading = false
        },
        [createAddress.pending]: (state) => {
            state.updatedAddressMessage = 'enviando...'
            // state.isLoading = true
        },
        [createAddress.rejected]: (state) => {
            state.updatedAddressMessage = 'Algo ha fallado, asegurate de llenar todos los campos'
            // state.isLoading = false
        }
    }
})

export const { clearUpdateAddress } = usersSlice.actions

export default usersSlice.reducer
