import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authCrud from "./authCrud"

export const login = createAsyncThunk(
    'auth/login',
    async (params) => {
            const response = await authCrud.login(params)
            localStorage.setItem('isLoggedIn', true)
            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('token', response.data.token)
            return response
    }
)

export const resetMail = createAsyncThunk(
    'auth/createMail',
    async (params) => {
        const response = await authCrud.resetMail(params)
        return response
    }
)

export const updateUser = createAsyncThunk(
    'auth/updateUser',
    async (params) =>  {
        await authCrud.updateUser(params)
        const user = JSON.parse(localStorage.getItem('user'))
        const userChanges = params.userChanges
        const updatedUser = {...user, ...userChanges}
        localStorage.setItem('user', JSON.stringify(updatedUser))
        return updatedUser
    }
)

const storedIsLoggedIn = localStorage.getItem('isLoggedIn')
const storedUser = JSON.parse(localStorage.getItem('user'))
const storedToken = localStorage.getItem('token')

const initialState = {
    isLoggedIn: storedIsLoggedIn || false, 
    user: storedUser || null,
    token: storedToken || null,
    message: null,
    resetMailSent: null,
    buttonDisabled: true,
    userMessage: ''
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        stateLogout(state) {
            state.isLoggedIn = false
            state.user = null
            state.token = null
            state.message = ''
        },
        resetState(state) {
            state.resetMailSent = null
            state.message = ''
        },
        enableButton(state) {
            state.buttonDisabled = false
        },
        clearUserMessage(state) {
            state.userMessage = ''
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true
            state.user = action.payload.data.user
            state.token = action.payload.data.token
            state.message = ''
        },
        [login.rejected]: (state) => {
            state.isLoggedIn = false
            state.user = null
            state.token = null
            state.message = 'Los datos ingresados son incorrectos. Por favor intentalo nuevamente'
        },
        [resetMail.pending]: (state) => {
            state.message = 'Enviando email...'
            state.buttonDisabled = true
        },
        [resetMail.fulfilled]: (state) => {
            state.resetMailSent = true
            state.message = 'Enviando... Será redirigido a la página de Inicio de Sesión.'
            state.buttonDisabled = true
        },
        [resetMail.rejected]: (state) => {
            state.resetMailSent = false
            state.message = 'Algo ha salido mal, por favor intentalo de nuevo'
        },
        [updateUser.rejected]: (state) => {
            state.userMessage = 'Ha ocurrido un error!, por favor intentalo de nuevo'
        },
        [updateUser.pending]: (state) => {
            state.userMessage = 'enviando...'
        },
        [updateUser.fulfilled]: (state, action) => {
            state.user.name = action.payload.name
            state.user.last_name_1 = action.payload.last_name_1
            state.user.last_name_2 = action.payload.last_name_2
            state.user.birthday = action.payload.birthday
            state.user.genre = action.payload.genre
            state.user.phone = action.payload.phone
            state.user.email = action.payload.email
            state.user.region = action.payload.region
            state.userMessage = 'Se han efectuado los cambios en la información del usuario'
        }
    }
})

export const logout = () => {
    return (dispatch) => {
        localStorage.removeItem('isLoggedIn')
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        dispatch(authSlice.actions.stateLogout())
    }
}

export const { resetState, enableButton, clearUserMessage } = authSlice.actions

export default authSlice.reducer