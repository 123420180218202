import axios from 'axios'
// import { store } from '../store'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((config) => {
    // const {
    //     auth: { token }
    //   } = store.getState()
    const token = localStorage.getItem('token')

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    },
    err => Promise.reject(err)
)

export default axiosInstance
