import axiosInstance from "../services/AxiosInstance"

export const PRODUCTS_URL = `${process.env.REACT_APP_API_URL}/private/products`

export function getAllProducts() {
    return axiosInstance.post(`${PRODUCTS_URL}/getAll`)
}

export function findProducts(typeUserId) {
    return axiosInstance.post(`${PRODUCTS_URL}/getAllByUser`,  {type_user_id: typeUserId}); //recives type user Id from auth
}

export function findProductsAdmin(queryParams) {
    return axiosInstance.post(`${PRODUCTS_URL}/getAll`, { queryParams }) //works without query params, needs revision to what can go through
}

const productsService = {
    getAllProducts,
    findProducts,
    findProductsAdmin
}

export default productsService