import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import customersCrud from "./customersCrud"

export const fetchCustomers = createAsyncThunk(
    'customers/fetchCustomers',
    async (params) => {
        const response = await customersCrud.findCustomers(params)
        return response
    }
)

export const fetchCustomersAsAdmin = createAsyncThunk(
    'customers/fetchCustomersAsAdmin',
    async (params) => {
        const response = await customersCrud.findCustomersAdmin(params)
        return response
    }
)

const initialState = {
    adminFetch: false,
    customers: null,
    customerstotal: null,
    error: null
}

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    extraReducers: {
        [fetchCustomers.fulfilled]: (state, action) => {
            state.customers = action.payload.data.users
            state.customerstotal = action.payload.data.users.length
            state.error = null
        },
        [fetchCustomers.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchCustomersAsAdmin.fulfilled]: (state, action) => {
            state.customers = action.payload.data.users
            state.customerstotal = action.payload.data.users.length
            state.adminFetch = true
            state.error = null
        },
        [fetchCustomersAsAdmin.rejected]: (state, action) => {
            state.error = action.error.message
        }
    }
})

export const { } = customersSlice.actions

export default customersSlice.reducer