import axiosInstance from "../services/AxiosInstance"

export const CUSTOMERS_URL = `${process.env.REACT_APP_API_URL}/private/clients`

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(id) {
    return axiosInstance.post(`${CUSTOMERS_URL}/getAllByUser`, {user_id: id});
}
  
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomersAdmin(queryParams) {
    return axiosInstance.post(`${CUSTOMERS_URL}/getAll`, { queryParams }); //doesn't require query params
}

const customersService = {
    findCustomers,
    findCustomersAdmin
}

export default customersService