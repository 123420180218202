import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import ordersCrud from './ordersCrud'

export const createOrder = createAsyncThunk(
    'orders/',
    async params => {
        const response = await ordersCrud.createOrder(params)
        return response
    }
)

export const fetchAllOrders = createAsyncThunk(
    'orders/fecthAll',
    async (params = false) => {
        const response = await ordersCrud.getAllOrders(params)
        return response
    }
)

export const fetchAllFiles = createAsyncThunk(
    'orders/fecthAllFiles',
    async () => {
        const response = await ordersCrud.getAllFiles()
        return response
    }
)

export const findOrderById = createAsyncThunk(
    'orders/findById',
    async params => {
        const response = await ordersCrud.getOrderById(params)
        return response
    }
)

export const findOrders = createAsyncThunk(
    'orders/findOrders',
    async params => {
        const response = await ordersCrud.findOrders(params)
        return response
    }
)

export const fetchAllUserOrders = createAsyncThunk(
    'orders/FetchAllByUser',
    async params => {
        const response = await ordersCrud.findOrdersByUser(params)
        return response
    }
)

const initialState = {
    areOrders: false,
    orders: [],
    ordersFiles: [],
    orderConsulted: [],
    orderIsCreated: false,
    orderCreated: null,
    error: null,
    orderButtonDisabled: false
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    extraReducers: {
        [createOrder.fulfilled]: (state, action) => {
            state.orderIsCreated = true
            state.orderCreated = action.payload.data
            state.error = null
            state.orderButtonDisabled = false
        },
        [createOrder.rejected]: (state, action) => {
            state.error = action.error.message
            state.orderButtonDisabled = false
        },
        [createOrder.pending]: (state) => {
            state.orderButtonDisabled = true
        },
        [fetchAllOrders.fulfilled]: (state, action) => {
            state.areOrders = true
            state.orders = action.payload.data.orders
            state.error = null
        },
        [fetchAllOrders.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchAllOrders.pending]: (state, action) => {
          state.areOrders = false;
        },
        [fetchAllFiles.fulfilled]: (state, action) => {
            state.areOrders = true
            state.ordersFiles = action.payload.data.data
            state.error = null
        },
        [fetchAllFiles.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchAllFiles.pending]: (state, action) => {
          state.fetchAllFiles = false;
        },
        [findOrderById.fulfilled]: (state, action) => {
            state.areOrders = true
            state.orderConsulted = [action.payload.data.order]
            state.error = null
        },
        [findOrderById.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [findOrderById.pending]: (state, action) => {
          state.areOrders = false;
        },
        [findOrders.fulfilled]: (state, action) => {
            state.areOrders = true
            state.orders = action.payload.data.orders
            state.error = null
        },
        [findOrders.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchAllUserOrders.fulfilled]: (state, action) => {
            state.areOrders = true
            state.orders = action.payload.data.orders
            state.error = null
        },
        [fetchAllUserOrders.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchAllUserOrders.pending]: (state, action) => {
          state.areOrders = false;
        }
    }
})

export const { } = ordersSlice.actions

export default ordersSlice.reducer
