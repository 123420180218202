// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from "./auth/auth"
import products from "./products/products"
import orders from "./orders/orders"
import users from "./users/users"
import customers from "./customers/customers"

const rootReducer = { navbar, layout, auth, products, orders, users, customers }

export default rootReducer
